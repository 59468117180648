import * as React from "react";
import { RecentSearches as RecentSearchesSharedUI } from "@shared-ui/retail-trips-legacy-recent-searches";
import { UitkSpacing } from "uitk-react-spacing";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

export const RecentSearches: React.FC<ComponentProps> = (props) => {
  const { templateComponent } = props;

  if (!templateComponent) {
    return null;
  }

  return (
    <div className="RecentSearches">
      <UitkSpacing margin={{ blockstart: "six", blockend: "four" }}>
        <RecentSearchesSharedUI />
      </UitkSpacing>
    </div>
  );
};

export default RecentSearches;
